import { Row, Col, Card, Button, Skeleton, Avatar, Image, Tooltip, Table, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import moment from 'moment';
import noDatafound from "../../assets/images/no-data-found.jpg";
import { PRICE } from "../../constants/conts";
import lang from "../../helper/lang";
const s3URL = 'https://sugamaya.s3.amazonaws.com/invent/public';

function View() {

  const sectionName = "Scanner Staff";
  const routeName = "scanner-staff";

  const navigate = useNavigate();
  const params = useParams();
  const { request } = useRequest();
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.viewVendorStaff + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    fetchData(params.id)
  }, [])

  return (
    <>
      <Row gutter={16}>
      <Row gutter={16}>
        <Col span={12} xs={12}>
          <Card title={`${lang("Personal") + ' ' + lang("Information")}`}>

            {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>{lang("Image")}:</h5>
                  <h6>
                    {
                      list && list.name ? (
                        !list.image ? (
                          <Avatar 
                            style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} 
                            className="cap" 
                            size={50}
                          >
                            {list.name.charAt(0)}
                          </Avatar>
                        ) : (
                          <Image className="image-radius" src={apiPath.assetURL + list.image} />
                        )
                      ) : (
                        <Avatar 
                          style={{ backgroundColor: "#00a2ae", verticalAlign: 'middle' }} 
                          className="cap" 
                          size={50}
                        >
                          N/A
                        </Avatar>
                      )
                    }
                  </h6>

                </div>

                <div className="view-inner-cls">
                  <h5>{lang("Name")}:</h5>
                  <h6><span className="cap">{list?.name || '-'}</span></h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{(lang("Email") || '') + ' ' + (lang("Address") || '')}:</h5>
                  <h6>{(list && list.email) ? list.email : '-'}</h6>
                </div>


                <div className="view-inner-cls">
                  <h5>{lang("Mobile") + ' ' + lang("Number")}:</h5>
                  <h6>{list ? '+' + list.country_code + '-' : '+965'}{list ? list.mobile_number : '-'}</h6>
                </div>

                <div className="view-inner-cls">
                  <h5>{(lang("Language") || '')}:</h5>
                  <h6>{(list && list.language) ? list.language : '-'}</h6>
                </div>


                <div className="view-inner-cls">
                  <h5>{(lang("Location") || '')}:</h5>
                  <h6>{(list && list.location) ? list.location : '-'}</h6>
                </div>


                <div className="view-inner-cls">
                  <h5>{(lang("DOB") || '')}:</h5>
                  <h6>{(list && list.dob && moment(list.dob).isValid()) ? moment(list.dob).format('DD-MMM-YYYY') : '-'}</h6>
                </div>


                <div className="view-inner-cls">
                  <h5>{(lang("Profile") || '') + ' ' + (lang("Completed") || '')}:</h5>
                  <h6>
                    {(list && typeof list.is_profile_completed === 'boolean') 
                      ? (list.is_profile_completed 
                          ? <Badge status="success" text="Completed" /> 
                          : <Badge status="error" text="Not Completed" />) 
                      : <Badge status="error" text="Not Available" />}
                  </h6>
                </div>
                      
                <div className="view-inner-cls">
                  <h5>{(lang("Accept_Terms_Conditions") || '')}:</h5>
                  <h6>
                    {(list && typeof list.accept_term_and_condition === 'boolean') 
                      ? (list.accept_term_and_condition 
                          ? <Badge status="success" text="Yes" /> 
                          : <Badge status="error" text="No" />) 
                      : <Badge status="error" text="Not Available" />}
                  </h6>
                </div>
                      
                <div className="view-inner-cls">
                  <h5>{(lang("Verified") || '') + ' ' + (lang("Status") || '')}:</h5>
                  <h6>
                    {(list && typeof list.is_verify === 'boolean') 
                      ? (list.is_verify 
                          ? <Badge status="success" text="Verified" /> 
                          : <Badge status="error" text="Not Verified" />) 
                      : <Badge status="error" text="Not Available" />}
                  </h6>
                </div>
                      
                <div className="view-inner-cls">
                  <h5>{(lang("Active") || '') + ' ' + (lang("Status") || '')}:</h5>
                  <h6>
                    {(list && typeof list.is_active === 'boolean') 
                      ? (list.is_active 
                          ? <Badge status="success" text="Active" /> 
                          : <Badge status="error" text="Inactive" />) 
                      : <Badge status="error" text="Not Available" />}
                  </h6>
                </div>
                      
                <div className="view-inner-cls">
                  <h5>{(lang("Registered_On") || '')}:</h5>
                  <h6>
                    {(list && list.created_at && moment(list.created_at).isValid()) 
                      ? moment(list.created_at).format('DD-MMM-YYYY') 
                      : '-'}
                  </h6>
                </div>


                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-primary" to={`/${routeName}`}>{lang("Back")}</Link>
                </div>
              </div>
            }

          </Card>
        </Col>

        <Col span={12} xs={12}>
          <Card title={`${lang("Vendor") + ' ' + lang("Business") + ' ' + lang("Profile")}`}>

            {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">

              {list?.vendor_id != null ?
                <>

                  <div className="view-inner-cls">
                    <h5>{lang("Admin") + ' ' + lang("Commission")} (%):</h5>
                    <h6>{list.vendor_id?.admin_commission ? list.vendor_id?.admin_commission : '0'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>{lang("Trade") + ' ' + lang("License") + ' ' + lang("Image")}:</h5>
                    <h6><Image width={60} src={list?.vendor_id?.trade_license_image ? list?.vendor_id?.trade_license_image : '-'} /></h6>
                  </div>
                  
                  <div className="view-inner-cls">
                    <h5>{lang("Trade") + ' ' + lang("License") + ' ' + lang("Number")}:</h5>
                    <h6>{list?.vendor_id?.trade_license_number ? list?.vendor_id?.trade_license_number : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>{lang("Business") + ' ' + lang("Name")}:</h5>
                    <h6>{list.vendor_id && list.vendor_id.business_name ? list.vendor_id.business_name : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>{lang("Business") + ' ' + lang("Location")}:</h5>
                    <h6>{list.vendor_id && list.vendor_id.business_address ? list.vendor_id.business_address : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>{lang("Start") + ' ' + lang("Time")}:</h5>
                    <h6>{list.vendor_id && list.vendor_id.business_start_time ? moment(list.vendor_id.business_start_time).format('HH:mm') : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>{lang("End") + ' ' + lang("Time")}:</h5>
                    <h6>{list.vendor_id && list.vendor_id.business_end_time ? moment(list.vendor_id.business_end_time).format('HH:mm') : '-'}</h6>
                  </div>

                  <div className="view-inner-cls">
                    <h5>{lang("Days_of_Week")}:</h5>
                    <h6>{list.vendor_id && list.vendor_id.days_of_week ? list.vendor_id.days_of_week : '-'}</h6>
                  </div>
               
                </>
              : <Image width={570} src={noDatafound} preview={false} />}
              </div>
            }

          </Card>
        </Col>
      </Row>
      </Row>
    </>
  );
}

export default View;
