import { Row, Col, Card, Button, Skeleton, Avatar, Image, Tooltip, Table, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import ConfirmationBox from "../../components/ConfirmationBox";
import ConfirmationDelete from "../../components/ConfirmationDelete";
import lang from "../../helper/lang";
import moment from "moment";

function View(data) {

  const sectionName = "Event";
  const routeName = "event";

  const params = useParams();
  const { request } = useRequest();
  const { confirmDelete } = ConfirmationDelete()
  const { showConfirm } = ConfirmationBox()

  const [refresh, setRefresh] = useState(false)
  const [list, setList] = useState({});
  const [requests, setRequests] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: apiPath.groupDetail + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  const fetchRequests = (id) => {
    setLoading(true);
    request({
      url: apiPath.requestsTableList + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        console.log(data.data, 5444)
        setRequests(data.data.docs);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  // const changeStatus = (id) => {
  //   const payload = {tableId: params.id}
  //   request({
  //     url: apiPath.requestsTableList + "/" + id,
  //     method: 'POST',
  //     data: payload,
  //     onSuccess: (data) => {
  //       setLoading(false);
  //       console.log(data.data, 5444)
  //       setRequests(data.data.docs);
  //     },
  //     onError: (error) => {
  //       ShowToast(error, Severty.ERROR)
  //     }
  //   })
  // }

  const Members = [
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name}) => {
        return (<a className="cap avatar-text" >{name ? name : 'Not Available'}</a>);
      }
    },
    {
        title: `${lang("Email")}`,
        dataIndex: "email",
        key: "email",
        render: (_, { email}) => {
          return (<a className="cap avatar-text" >{email ? email : 'Not Available'}</a>);
        }
      },
    {
      title: `Mobile Number`,
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (_, { mobile_number,country_code }) => {
        return (<div className="for-bold-mainhhhh" >{mobile_number ? "+" +country_code+ " "+mobile_number : 0}</div>);
      },
    }
  ];
  const Events = [
    {
      title: `${lang("Event Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { event_id}) => {
        return (<a className="cap avatar-text" >{event_id ? event_id.name : 'Not Available'}</a>);
      }
    },
    {
        title: `${lang("Event Type")}`,
        dataIndex: "event-type",
        key: "event-type",
        render: (_, { event_id}) => {
          return (<a className="cap avatar-text" >{event_id ? event_id.event_type : 'Not Available'}</a>);
        },
      },
    {
      title: `Event Date`,
      dataIndex: "event_date",
      key: "event_date",
      render: (_, {event_id }) => {
        return (
          <a className="avatar-text">
            {event_id && event_id.recurringDate ? (
              moment(event_id.recurringDate).format("MM-DD-YYYY")
            ) : (
              "Not available"
            )}
          </a>
        )
      },
    },
    {
      title: `Members`,
      dataIndex: "members",
      key: "members",
      render: (_, {friend_id }) => {
        return (<a className="avatar-text">{friend_id.map((name) => name.name).join(', ')}</a>);
      },
    },
    {
      title: `Booking`,
      dataIndex: "bookings",
      key: "bookings",
      render: (_, {event_id }) => {
        // console.log(bookings?.tableReservations, "table reservations>>>>>>>>>>")
        return (<div className="for-bold-mainhhhh">{event_id?.bookings?.tableReservations? "Table Reserved": "GuestList"}</div>);
      },
    }
  ]

  useEffect(() => {
    console.log(data, 16000);
    fetchData(params.id);
    // fetchRequests(params.id)
  }, [refresh])

  return (
    <>
      <Row gutter={[24,24]}>
        <Col span={24} xs={24}>
          <Card title={`${list.group_name} group details`}>

            {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) :
              <div className="view-main-list">
                <Row gutter={[24,24]}>

                <Col span={12} xs={24} md={24}>
                <Card title={"Group Hosts"} >
        

                {loading ? [1, 2, 3, 4].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={Members}
                        dataSource={list.user_id}
                        onChange={fetchData}
                        className="ant-border-space"
                      />
                    </div>
                  </div>
                }
            
          </Card>
          </Col>
                
              <Col span={12} xs={24} md={24}>
              <Card title={"Member details"} >
                {loading ? [1, 2, 3, 4].map(item => <Skeleton active key={item} />) :
                  <div className="view-main-list">
                    <div className="table-responsive customPagination">
                      <Table
                        loading={loading}
                        columns={Members}
                        dataSource={list.friend_id}
                        pagination={{ defaultPageSize: 20}}

                        onChange={fetchData}
                        className="ant-border-space"
                      />
                    </div>
                  </div>
                }
          </Card>
          </Col>
        
          <Col span={12} xs={24} md={24}>
            <Card title={"Event details"}>
              {loading ? (
                [1, 2, 3, 4].map(item => <Skeleton active key={item} />) 
              ) : !list.events || list.events.length === 0 ? ( 
                <div>No events available</div> 
              ) : (
                <div className="view-main-list">
                  <div className="table-responsive customPagination">
                    <Table
                      loading={loading}
                      columns={Events}
                      dataSource={list.events} 
                      onChange={fetchData}
                      className="ant-border-space"
                    />
                  </div>
                </div>
              )}
            </Card>
          </Col>


                </Row>
                <div className="view-inner-cls float-right mb-1">
                  <Button className="ant-btn ant-btn-primary" onClick={() => window.history.back()}>{lang("Back")}</Button>
                </div>

              </div>
            }

          </Card>
        </Col>
      </Row>
    </>
  );
}


export default View;
